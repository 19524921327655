<template lang="pug">
  .project-stages                        
    .border.p-2
      .float-end
        button.btn.btn-primary.btn-sm(type='button' data-bs-toggle='modal' data-bs-target='#projectStageModal' @click="setDefaultProjectStage")
          font-awesome-icon.me-2(icon="plus-circle")
          span Añadir
      strong Etapas
      table.table.table-border.table-sm
        tbody
          tr(v-for="projectStage in projectStages" :key="'projectStage' + projectStage.id")
            td
              div
                strong {{projectStage.name}}
              div
                .float-end
                  span.me-2 {{projectStage.start}} - {{projectStage.end}}
                  button.btn.btn-link.text-dark(type='button' data-bs-toggle='modal' data-bs-target='#projectStageModal' @click="editProjectStage(projectStage)")
                    font-awesome-icon(icon="edit")
                  button.btn.btn-link.text-dark(type='button' @click="deleteProjectStage(projectStage)")
                    font-awesome-icon(:icon="['fas', 'trash']")
              span.badge.me-2(:style="'background:' + projectStage.project_stage_status.background + ';color:' + projectStage.project_stage_status.color + ';'") {{projectStage.project_stage_status.name}}
                            
      #projectStageModal.modal(v-if="projectStage" tabindex='-1' aria-hidden="true") 
        .modal-dialog.modal-lg
          .modal-content
            .modal-header
              h5.modal-title Añadir una nueva etapa
              button#projectStageModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
            .modal-body
              draw-simp-form(
                :module="projectStageModule" 
                :value="projectStage"
                @createRegister="createProjectStage"
                @updateRegister="updateProjectStage"
              )
</template>

<script>
  import jQuery from 'jquery'
  import DrawSimpForm from '../../components/forms/DrawSimp.vue'
  export default {
    name: 'ProjectStages',
    components: {
      DrawSimpForm
    },
    props: {
      projectId: {type: Number, default: 0},
      projectStages: {type: Array, default: function() {return [] }},
    },
    beforeMount() {
      
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'project_stages'})
        .then((res) => {
          this.projectStageModule = res
        })
    },
    data () {
      return {
        projectStageModule: {},
        projectStage: {}
      }
    },
    methods: {
      setDefaultProjectStage() {
        this.projectStage.project_id = this.projectId
      },
      editProjectStage(projectStage) {
        this.projectStage = projectStage
      },
      createProjectStage(res) {
        this.$emit("createProjectStage", res) 
        this.projectStage = {}
        jQuery('#projectStageModalClose').trigger('click')
      },
      updateProjectStage(res) {
        this.$emit("updateProjectStage", res) 
        this.projectStage = {}
        jQuery('#projectStageModalClose').trigger('click')
      },
      deleteProjectStage(projectStage) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: this.projectStageModule.url,
              id: projectStage.id
            }).then((res) => {
              this.$emit("deleteProjectStage", res) 
            })
          }
        });
      }
    }
  }
</script>