import appService from "./appService";

const filesService = {
    upload(file) {
        const formData = new FormData()
        formData.append('file', file, file.name)

        return appService.post('/files/upload', formData)
            .then((res) => res.data)
    },
    uploadPasteFile(base64File) {
        const formData = new FormData()
        formData.append('file', base64File)

        return appService.post('/files/upload_paste_image', formData)
            .then((res) => res.data)
    }
}

export default filesService;
