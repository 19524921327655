<template lang="pug">
  .input-wysiwyg
    ckeditor(:config="editorConfig" v-model="localValue")
</template>
<script>
export default {
  name: 'InputWysiwyg',
  props: {
    value: {type: String, default: ''},
  },
  data () {
    return {
      localValue: '',
      editorConfig: {}
    }
  },
  beforeMount() {
    this.localValue = this.value
  },
  watch: {
      localValue (newValue) {
       this.$emit('input', newValue)
     },
     value (newValue) {
       this.localValue = newValue
     }
  }
}
</script>
<style scoped>
</style>