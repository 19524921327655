  <template lang="pug">
  .task-view(v-if="task && task.id")
    .border
      .row.g-0
        .col-lg-9.p-2
          template(v-if="editableNameDescription")
            input.form-control.mb-2(type="text" v-model="taskName")
            ckeditor.mb-2.mt-2(:config="{}" v-model="taskDescription")
            .btn-group
              button.btn.btn-primary(@click="updateNameDescription()") 
                font-awesome-icon.me-2(icon="save")
                span Guardar
              button.btn.btn-secondary(@click="editableNameDescription = false") 
                font-awesome-icon.me-2(icon="ban")
                span Cancelar
          template(v-else)
            h3.pb-2.mb-2.border-bottom(:class="task.error ? 'text-danger' : ''" @dblclick="editableNameDescription = !editableNameDescription") {{task.task_description.name}}
            .mb-2.border-bottom.ps-2.pe-2
              template(v-if="task.task_description.description")
                div(style="min-height:320px" v-html="task.task_description.description" @dblclick="editableNameDescription = !editableNameDescription")
              template(v-else)
                p(style="min-height:320px" @dblclick="editableNameDescription = !editableNameDescription") Sin descripción
            nav.nav
              a.nav-link(:class="currentTab == 'comments' ? 'active' : ''" @click="setTab('comments')") Comentarios
              a.nav-link(:class="currentTab == 'task_times' ? 'active' : ''" @click="setTab('task_times')") Registros de tiempo

            template(v-if="currentTab == 'comments'")
              view-comment(:module="taskCommentModule" :parentId="task.id" parentKey="task_id" :comments="task.task_comments" @newComment="addTaskComment")
            template(v-if="currentTab == 'task_times'")
              table.table.table-striped
                thead
                  tr
                    th Usuario
                    th Fecha
                    th Duración
                tbody
                  tr(v-for="taskTime in task.task_times")
                    td {{taskTime.user.name}}
                    td {{taskTime.start}}
                    td {{taskTime.duration | timeInHours}}

        .col-lg-3.bg-light.border-start
          h1.mb-0.ps-2.pt-3.pb-3.border-bottom
            .btn.btn-danger.rounded-circle.me-2(v-if="myCurrentStartedTask && myCurrentStartedTask.id" @click="endTask")
              font-awesome-icon(icon="stop")
            .btn.btn-success.rounded-circle.me-2(v-else @click="startTask")
              font-awesome-icon(icon="play")
            span.align-middle(:class="currentDuration ? 'text-success' : ''") {{totalDuration | timeInHours}}
          .ps-2.pb-2.pt-2.border-bottom.bg-success.text-white(v-if="currentDuration")
            span Tiempo del intervalo actual {{currentDuration | timeInHours}}
          small.ps-2 
            font-awesome-icon.me-2(icon="tasks")
            span Tipo 
          .ps-2.pb-2.mb-2.border-bottom
            .btn-group
              button.btn.btn-sm.dropdown-toggle.pt-0.pb-0(data-bs-toggle="dropdown" aria-expanded="false" :style="'background:' + task.task_type.background + ';color:' + task.task_type.color + ';'") 
                strong {{task.task_type.name}}&nbsp;
              ul.dropdown-menu
                li(v-for="taskType in taskTypes")
                  a.dropdown-item(@click="updatePartial('task_type_id', taskType.id)") {{taskType.label}}
          small.ps-2 
            font-awesome-icon.me-2(icon="traffic-light")
            span Prioridad
          .ps-2.pb-2.mb-2.border-bottom
            .btn-group
              button.btn.btn-sm.dropdown-toggle.pt-0.pb-0(data-bs-toggle="dropdown" aria-expanded="false" :style="'background:' + task.task_priority.background + ';color:' + task.task_priority.color + ';'") 
                strong {{task.task_priority.name}}&nbsp;
              ul.dropdown-menu
                li(v-for="taskPriority in taskPriorities")
                  a.dropdown-item(@click="updatePartial('task_priority_id', taskPriority.id)") {{taskPriority.label}}
          small.ps-2 
            font-awesome-icon.me-2(icon="flag")
            span Estado
          .ps-2.pb-2.mb-2.border-bottom
            .btn-group
              button.btn.btn-sm.dropdown-toggle.pt-0.pb-0(data-bs-toggle="dropdown" aria-expanded="false" :style="'background:' + task.task_status.background + ';color:' + task.task_status.color + ';'") 
                strong {{task.task_status.name}}&nbsp;
              ul.dropdown-menu
                li(v-for="taskStatus in taskStatuses")
                  a.dropdown-item(@click="updatePartial('task_status_id', taskStatus.id)") {{taskStatus.label}}
          small.ps-2 
            font-awesome-icon.me-2(icon="layer-group")
            span Lista de tarea
          .ps-2.pb-2.mb-2.border-bottom
            .btn-group
              button.btn.btn-sm.btn-secondary.dropdown-toggle.pt-0.pb-0(data-bs-toggle="dropdown" aria-expanded="false") 
                strong(v-if="task.task_group_id") {{task.task_group.name}}&nbsp;
                strong(v-else) Sin grupo
              ul.dropdown-menu
                li(v-for="taskGroup in taskGroups")
                  a.dropdown-item(@click="updatePartial('task_group_id', taskGroup.id)") {{taskGroup.label}}
          template(v-if="editableDates")
            small.ps-2 
              font-awesome-icon.me-2(icon="calendar")
              span Inicio
            input.form-control(type="date" v-model="task.start_date" @keyup.enter="update()")
            input.form-control(type="time" v-model="task.start_hour" @keyup.enter="update()")
            small.ps-2
              font-awesome-icon.me-2(icon="calendar")
              span Fin
            input.form-control(type="date" v-model="task.end_date" @keyup.enter="update()")
            input.form-control(type="time" v-model="task.end_hour" @keyup.enter="update()")
          template(v-else)
            small.ps-2 
              font-awesome-icon.me-2(icon="calendar")
              span Inicio
            h4.ps-2.pb-2.pt-2.border-bottom(@dblclick="editableDates = !editableDates")
              span {{task.start_date | formatDate}} {{task.start_hour | formatTime}}
            small.ps-2
              font-awesome-icon.me-2(icon="calendar")
              span Fin
            h4.ps-2.pb-2.pt-2.border-bottom(@dblclick="editableDates = !editableDates") 
              span {{task.end_date | formatDate}} {{task.end_hour | formatTime}}
          small.ps-2 
            font-awesome-icon.me-2(icon="user")
            span Usuarios
          div.ps-2.pb-2.pt-2.border-bottom
            ul.list-group.list-group-flush
              li.list-group-item(v-for="user in task.users")
                strong {{user.name}}
          div.ps-2.pb-2.pt-2.border-bottom
            small
              font-awesome-icon.me-2(icon="paperclip")
              span Ficheros adjuntos
            .list-group.list-group-flush.mt-1
              a.list-group-item.list-group-item-action(v-for="taskFile in task.task_files" :href="taskFile.path" target="_blank") {{taskFile.name}}
            hr
            div
              .row
                .col
                  //.btn-group
                    button.btn.btn-secondary.btn-sm(type="button")
                      font-awesome-icon(icon="paste")
                    button.btn.btn-primary.btn-sm(type="button")
                      font-awesome-icon(icon="upload")
                  div
                    input.form-control(type="file" @change="handleFileUpload" multiple)
          div.ps-2.pe-2.pb-2.pt-2
            .d-grid.gap-2.mx-auto
              button.btn.btn-primary.btn-sm(@click="editTask(task.id)")
                font-awesome-icon.me-2(icon="edit")
                span Editar
              button.btn.btn-danger.btn-sm(@click="deleteTask(task.id)")
                font-awesome-icon.me-2(icon="trash")
                span Eliminar

</template>

<script>
  import ViewComment from '../comments/View.vue'
  import filesService from '../../services/api/files'

  export default {
    name: 'TaskView',
    props: {
      taskId: {type: Number, default: 0},
    },
    components: {
      ViewComment
    },
    beforeMount() {
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'task_comments'})
        .then((res) => {
          this.taskCommentModule = res
        })

      this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: this.taskId})
        .then((res) => {
          this.task = res
          this.taskName = res.task_description.name
          if(res.task_description.description) {
            this.taskDescription = res.task_description.description
          } else {
            this.taskDescription = ''
          }
        })

      this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_types',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.taskTypes = res
        })

      this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_statuses',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.taskStatuses = res
        })

      this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_priorities',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.taskPriorities = res
        })

      this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_groups',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.taskGroups = res
        })

      if(this.myCurrentStartedTask) {
          this.currentDuration = this.$options.filters.diffToNow(this.myCurrentStartedTask.start)

          if(!this.currentDurationInterval) {
            this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
          }
        }
    },
    beforeDestroy() {
      if(this.currentDurationInterval) {
        clearInterval(this.currentDurationInterval)
        this.currentDuration = 0
        this.currentDurationInterval = null
      }
    },
    data () {
      return {
        currentModule: {},
        taskCommentModule: {},
        moduleUrl: 'tasks',
        task: {},
        currentDuration: 0,
        currentDurationInterval: null,
        isEditName: false,
        currentTab: 'comments',
        taskTypes: [],
        taskStatuses: [],
        taskPriorities: [],
        taskGroups: [],
        editableNameDescription: false,
        editableDates: false,
        taskName: '',
        taskDescription: ''
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      startedTasks() {
        if(this.$store.state.taskModule.startedTasks) {
          return this.$store.state.taskModule.startedTasks
        } else {
          return []
        }
      },
      currentStaredTask() {
        let index = this.startedTasks.findIndex(x => x.id == this.taskId)

        if(index > -1) {
          return this.startedTasks[index]
        } else {
          return {}
        }
      },
      myCurrentStartedTask() {
        let myCurrentStartedTask = {}
        if(this.currentStaredTask && this.currentStaredTask.task_times) {
          let index = this.currentStaredTask.task_times.findIndex(x => x.user_id == this.currentUser.id && !x.duration)

          if(index > -1) {
            myCurrentStartedTask = this.currentStaredTask.task_times[index]
          }
        }

        return myCurrentStartedTask
      },
      totalDuration() {
        return this.task.duration + this.currentDuration
      }
    },
    watch: {
      taskId(value) {
        this.currentDuration = 0
        if(this.currentDurationInterval) {
          clearInterval(this.currentDurationInterval)
          this.currentDurationInterval = null
          this.currentDuration = 0
        }

        this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: value})
        .then((res) => {
          this.task = res
          this.taskName = res.task_description.name
          if(res.task_description.description) {
            this.taskDescription = res.task_description.description
          } else {
            this.taskDescription = ''
          }
        })
      },
      myCurrentStartedTask(value) {
        if(value) {
          this.currentDuration = this.$options.filters.diffToNow(value.start)

          if(!this.currentDurationInterval) {
            this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
          }
        } else {
          this.currentDuration = 0
          clearInterval(this.currentDurationInterval)
          this.currentDurationInterval = null
        }
      }
    },
    methods: {
      setTab(tab) {
        this.currentTab = tab
      },
      startTask() {
        this.$store.dispatch("startTask", {
          taskId: this.taskId
        }).then((task) => {
          this.task = task

          if(this.currentDurationInterval) {
            clearInterval(this.currentDurationInterval)
            this.currentDurationInterval = null
            this.currentDuration = 0
          }
          this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
        })
      },
      endTask() {
        this.$store.dispatch("endTask", {
          taskId: this.taskId
        }).then((task) => {
          this.task = task
          if(this.currentDurationInterval) {
            clearInterval(this.currentDurationInterval)
            this.currentDurationInterval = null
          }
          this.currentDuration = 0
        })
      },
      updateNameDescription() {
        this.task.name = this.taskName
        this.task.description = this.taskDescription
        this.update()
      },
      updatePartial(key, value) {
        this.task[key] = value
        this.update()
      },
      update() {
        this.$store.dispatch("updateRegister", {
          moduleUrl: this.currentModule.url,
          id: this.task.id,
          data: this.task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.task = res

            this.$store.commit("updateTasks", res)

            this.editableNameDescription = false
            this.editableDates = false
          }
        })
      },
      editTask(taskId) {
        this.$emit("closeModal")
        this.$router.push('/tasks/' + taskId + '/edit')
      },
      deleteTask(taskId) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este element?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result) {
            this.$emit("closeModal")
            this.$store.dispatch("deleteRegister", {
              moduleUrl: 'tasks',
              id: taskId
            }).then((res) => {
              this.$store.commit("deleteTasks", res)
            })
          }
        });
      },
      addTaskComment(comment) {
        this.task.task_comments.push(comment)
      },
      handleFileUpload (e) {
          e.target.files.forEach((file) => {
            if(file) {
              filesService.upload(file)
                .then((response) => {
                    if(response) {
                      let data = {
                        task_id: this.taskId,
                        name: file.name,
                        path: response
                      }

                      this.$store.dispatch('createRegister', {
                        moduleUrl: 'task_files',
                        data: data
                      })
                      .then((res) => {
                        if(res.id) {
                          this.task.task_files.push(res)
                        }
                      })
                    }
                })
            }
          })
      },
    }
  }
</script>