<template lang="pug">
  .account-emails
    .border.p-2
      .float-end
        button.btn.btn-primary.btn-sm(type='button' data-bs-toggle='modal' data-bs-target='#accountEmailModal' @click="setDefaultAccountEmail")
          font-awesome-icon.me-2(icon="plus-circle")
          span Añadir
      strong Correo Electrónico
      table.table.table-border.table-sm
        tbody
          tr(v-for="accountEmail in accountEmails" :key="'accountEmail' + accountEmail.id")
            td
              div
                .float-end
                  button.btn.btn-link.text-dark(type='button' data-bs-toggle='modal' data-bs-target='#accountEmailModal' @click="editAccountEmail(accountEmail)")
                    font-awesome-icon(icon="edit")
                  button.btn.btn-link.text-dark(type='button' @click="deleteAccountEmail(accountEmail)")
                    font-awesome-icon(:icon="['fas', 'trash']")
                strong {{accountEmail.email}}
                br
                small {{accountEmail.name}}
              
                            
      #accountEmailModal.modal(v-if="accountEmail" tabindex='-1' aria-hidden="true") 
        .modal-dialog.modal-lg
          .modal-content
            .modal-header
              h5.modal-title Añadir un correo electrónico
              button#accountEmailModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
            .modal-body
              draw-simp-form(
                :module="accountEmailModule" 
                :value="accountEmail"
                @createRegister="createAccountEmail"
                @updateRegister="updateAccountEmail"
              )
</template>

<script>
  import jQuery from 'jquery'
  import DrawSimpForm from '../forms/DrawSimp.vue'
  export default {
    name: 'AccountEmails',
    components: {
      DrawSimpForm
    },
    props: {
      accountId: {type: Number, default: 0},
      accountEmails: {type: Array, default: function() {return [] }},
    },
    beforeMount() {
      
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'account_emails'})
        .then((res) => {
          this.accountEmailModule = res
        })
    },
    data () {
      return {
        accountEmailModule: {},
        accountEmail: {}
      }
    },
    methods: {
      setDefaultAccountEmail() {
        console.log('test')
        this.accountEmail = {}
        this.accountEmail.account_id = this.accountId
      },
      editAccountEmail(accountEmail) {
        this.accountEmail = accountEmail
      },
      createAccountEmail(res) {
        this.$emit("createAccountEmail", res) 
        this.accountEmail = {}
        jQuery('#accountEmailModalClose').trigger('click')
      },
      updateAccountEmail(res) {
        this.$emit("updateAccountEmail", res) 
        this.accountEmail = {}
        jQuery('#accountEmailModalClose').trigger('click')
      },
      deleteAccountEmail(accountEmail) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: this.accountEmailModule.url,
              id: accountEmail.id
            }).then((res) => {
              this.$emit("deleteAccountEmail", res) 
            })
          }
        });
      }
    }
  }
</script>