<template lang="pug">
  .task-list
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
            .row
              .col-lg-2.ms-2.pe-1
                input.form-control.p-1(type="text" v-model="filter" placeholder="Buscar...")
              .col-lg-2.ps-1.pe-1
                v-select.bg-white(:options="taskGroups" :reduce="label => label.id"  label="label" v-model="filterTaskGroup" placeholder="Filtrar por grupo")
              .col-lg-2.ps-1.pe-1
                v-select.bg-white(:options="taskStatuses" :reduce="label => label.id"  label="label" v-model="filterTaskStatus" placeholder="Filtrar por estado")
          .p-2
            .btn-group.me-2.pt-1
              button.btn.btn-primary.btn-sm 
                font-awesome-icon.me-2(icon="bars")
                span Listado
              button.btn.btn-secondary.btn-sm
                font-awesome-icon.me-2(icon="columns")
                span Canvas
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card
                .card-body
                  div(v-for="task in sortedTasks" @click="showTask(task.id)" data-bs-toggle="modal" data-bs-target="#taskModal")
                    task-list-item(:task="task")
      .fixed-bottom.bg-light.p-3.border-top(style="z-index: 1000")
        .row
          .col-lg-6
            input.form-control.p-1(type="text" placeholder="Añadir una nueva tarea" v-model="taskName" @keyup.enter="createTask")
          .col-lg-2.col-md-6
            v-select.vue-select-up(:options="taskGroups" :reduce="label => label.id"  label="label" v-model="taskGroupId" append-to-body :calculate-position="withPopper")
          .col-lg-2.col-md-2
            input.form-control.p-1(type="date" v-model="taskDateStart")
          .col-lg-2.col-md-2
            .d-grid.gap-2.d-md-flex.justify-content-md-end
              button.btn.btn-secondary.btn-sm(type="button" data-bs-toggle="modal" data-bs-target="#createAdvancedTaskModal")
                font-awesome-icon.me-2(icon="cogs")
                span Avanzado
              button.btn.btn-primary.btn-sm(@click="createTask")
                font-awesome-icon.me-2(icon="save")
                span Crear
    #taskModal.modal.fade(tabindex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true')
      .modal-dialog.modal-xl
        .modal-content(v-if="taskId")
          button#taskModalClose.btn-close.d-none(type='button' data-bs-dismiss='modal' aria-label='Close')
          task-view(
            :taskId="taskId"
            @closeModal="closeModal"
            )
    #createAdvancedTaskModal.modal.fade(tabindex='-1' aria-labelledby='createAdvancedTaskModalLabel' aria-hidden='true')
      .modal-dialog.modal-xl
        .modal-content
          .modal-header
            h5.modal-title Añadir una nueva tarea
            button#createAdvancedTaskModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            draw-simp-form(
                :module="currentModule" 
                :value="{}"
                @createRegister="createAdvancedTask"
              )
</template>

<script>
  import TaskView from '../../components/tasks/TaskView.vue'
  import TaskListItem from '../../components/tasks/TaskListItem.vue'
  import DrawSimpForm from '../../components/forms/DrawSimp.vue'
  import jQuery from 'jquery'
  import { createPopper } from '@popperjs/core'
  export default {
    name: 'TaskList',
    components: {
      TaskView,
      TaskListItem,
      DrawSimpForm
    },
    beforeMount() {
      this.$store.dispatch("getMyTasks")
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'tasks'})
        .then((res) => {
          this.currentModule = res
        })
      this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_groups',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.taskGroups = res
        })
      this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_statuses',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.taskStatuses = res
        })
    },
    data () {
      return {
        taskId: 0,
        filter: '',
        filterTaskStatus: '',
        filterTaskGroup: '',
        currentModule: {},
        taskName: '',
        taskGroupId: null,
        taskGroups: [],
        taskStatuses: [],
        taskDateStart: null,
        placement: 'top'
      }
    },
    computed: {
      tasks() {
        return this.$store.state.taskModule.tasks
      },
      filteredTasks() {
        let filteredTasks = this.tasks

        filteredTasks = filteredTasks.filter(task => {
          return task.task_description.name.toLowerCase().includes(this.filter) || task.task_description.description.toLowerCase().includes(this.filter)
        })

        if(this.filterTaskGroup) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_group_id == this.filterTaskGroup
          })
        }

        if(this.filterTaskStatus) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_status_id == this.filterTaskStatus
          })
        }

        return filteredTasks
      },
      sortedTasks() {
        
        let tasks = this.filteredTasks

        return tasks.sort((a, b) => {
          
          if(a.start_date && b.start_date && a.start_date > b.start_date) return 1
          if(a.start_date && b.start_date && a.start_date < b.start_date) return -1
          if(a.start_date && !b.start_date ) return -1
          if(!a.start_date && b.start_date ) return 1
          if(a.task_priority.weight > b.task_priority.weight) return -1
          if(a.task_priority.weight < b.task_priority.weight) return 1
          
          return 0
        })
      }
    },
    methods: {
      setTab(tab) {
        this.currentTab = tab
      },
      showTask(taskId) {
        this.taskId = taskId
      },
      createTask() {
        if(this.taskName) {
          this.$store.dispatch('createRegister', {
            moduleUrl: 'tasks',
            data: {
              name: this.taskName,
              task_group_id: this.taskGroupId,
              start_date: this.taskDateStart
            }
          }).then((res) => {
            this.$store.commit("addTasks", res)
            this.taskName = ''
          }) 
        }
      },
      createAdvancedTask(task) {
        this.$store.commit("addTasks", task)
      },
      deleteTask() {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este element?',
          icon: 'warning',
          showCancelButton: true
        }).then(function (result) {
          console.log(result)
        });
      },
      closeModal() {
        jQuery('#taskModalClose').trigger('click')
      },
      withPopper(dropdownList, component, { width }) {
        dropdownList.style.width = width

        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1],
              },
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn({ state }) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              },
            },
          ],
        })

        return () => popper.destroy()
      },
    }
  }
</script>
<style scoped>
  .main-content {
    padding-bottom: 60px;
  }
</style>