<template lang="pug">
  .logout
    .row
      .col-md-4
      .col-md-4
        .card
          .card-body
            p Saliendo del sistema...

</template>

<script>

export default {
  name: 'Logout',
  data () {
    return {
      loading: false,
      message: '',
      username: '',
      password: '',
      user: {}
    }
  },
  mounted() {
    localStorage.removeItem('user')
    this.logout()
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(
          () => {
            location.reload()
          },
          error => {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
    }
  }

}
</script>

<style lang="scss">
  
</style>
