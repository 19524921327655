import Vue from 'vue'
import Vuex from 'vuex'

import authModule from "./auth"
import moduleModule from "./modules"
import taskModule from "./tasks"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentUser: {},
    },
    mutations: {
        setCurrentUser (state, currentUser) {
            state.currentUser = currentUser
        },
    },
    actions: {
        setCurrentUser(context, user) {
            context.commit('setCurrentUser', user)
        }
    },
    modules: {
        authModule,
        moduleModule,
        taskModule
    }
})