import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "../views/Login.vue"
import Logout from "../views/Logout.vue"

import AccountShow from "../views/accounts/Show.vue"
import BudgetShow from "../views/budgets/Show.vue"
import OrderShow from "../views/orders/Show.vue"
import DeliveryNoteShow from "../views/delivery_notes/Show.vue"
import InvoiceShow from "../views/invoices/Show.vue"
import ProjectShow from "../views/projects/Show.vue"
import TaskShow from "../views/tasks/Show.vue"
import TaskList from "../views/tasks/List.vue"

import ModuleList from "../views/modules/List.vue"
import ModuleAdd from "../views/modules/Add.vue"
import ModuleEdit from "../views/modules/Edit.vue"

Vue.use(VueRouter);

const routes = [
  { path: '/login', name: 'login', component: Login },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '/:moduleUrl', name: 'module_list', component: ModuleList },
  { path: '/:moduleUrl/add', name: 'module_add', component: ModuleAdd },
  { path: '/accounts/:id', name: 'accountsShow', component: AccountShow },
  { path: '/budgets/:id', name: 'budgetShow', component: BudgetShow },
  { path: '/orders/:id', name: 'orderShow', component: OrderShow },
  { path: '/tasks/list', name: 'taskList', component: TaskList },
  { path: '/tasks/:id/show', name: 'taskShow', component: TaskShow },
  { path: '/delivery_notes/:id', name: 'deliveryNoteShow', component: DeliveryNoteShow },
  { path: '/invoices/:id', name: 'invoiceShow', component: InvoiceShow },
  { path: '/projects/:id', name: 'projectShow', component: ProjectShow },
  { path: '/:moduleUrl/:id/edit', name: 'module_edit', component: ModuleEdit },
  { path: '/:parentModuleUrl/:parentId/:moduleUrl', name: 'child_module_list', component: ModuleList },
  { path: '/:parentModuleUrl/:parentId/:moduleUrl/add', name: 'child_module_add', component: ModuleAdd },
  // { path: '/:moduleUrl/:id', name: 'module_show', component: ModuleShow },
]

const router = new VueRouter({
  mode: 'history',
  routes
});
  
export default router

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});