<template lang="pug">
  .project-show(v-if="project.id")
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
            .btn-group.me-2.ms-2
              .dropdown
                button.btn.btn-outline-primary.dropdown-toggle(type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false")
                  font-awesome-icon.me-2(:icon="['fas', 'plus-circle']")
                  span Generar
                ul.dropdown-menu(aria-labelledby="dropdownMenuButton1")
                  li
                    a.dropdown-item(href="#") Tarea
                    a.dropdown-item(href="#") Etapa
          .p-2
            .btn-group.me-2
              a.btn.btn-outline-secondary(type='button' href="/projects")
                font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
                span Volver
            .btn-group.me-2
              a.btn.btn-outline-primary(type='button' :href="'/projects/' + project.id + '/edit'")
                font-awesome-icon.me-2(:icon="['fas', 'edit']")
                span Editar
            .btn-group.me-2
              button.btn.btn-outline-danger(type='button' @click="deleteProject()")
                font-awesome-icon.me-2(:icon="['fas', 'trash']")
                span Eliminar
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card
                .card-body
                  .row
                    .col-4
                      h5.card-title {{project.name}}
                      p 
                        span(v-if="project.code") {{'#' + project.code}}
                    .col-8
                      .d-flex.flex-row-reverse.text-center.recap-totals
                        .p-2.recap-totals-item
                          small Tareas
                          strong -
                        .p-2.recap-totals-item
                          small Etapas
                          strong {{project.project_stages.length}}
                  .row.mt-2
                    .col-12
                      nav.nav.mb-2
                        a.nav-link(:class="currentTab == 'info' ? 'active' : ''" aria-current='page' @click="setTab('info')") Información
                    .col-12
                      .p-2
                        template(v-if="currentTab == 'info'")
                          .row
                            .col-4
                              .border.p-2
                                strong Información básica
                                table.table.table-border.table-sm
                                  tbody
                                    tr
                                      td
                                        small Cuenta
                                        div 
                                          strong(v-if="project.account_id") {{project.account.name}}
                                          strong(v-else) -
                                    tr
                                      td
                                        small Fecha inicial
                                        div 
                                          strong {{project.start}}
                                    tr
                                      td
                                        small Fecha final
                                        div 
                                          strong {{project.end}}
                                    tr
                                      td
                                        small Tiempo estimado
                                        div 
                                          strong {{project.hours}}h
                                    tr
                                      td
                                        small Usuarios
                                        div
                                          ul.list-group.list-group-flush
                                            li.list-group-item(v-for="user in project.users")
                                              strong {{user.name}}
                                    tr
                                      td
                                        small Etiquetas
                                        div
                                          span.badge.bg-danger.me-2 VIP
                                          span.badge.bg-success Distribuidor
                            .col-4
                              project-stage-component(
                                :projectId="id" 
                                :projectStages="project.project_stages" 
                                @createProjectStage="createProjectStage" 
                                @updateProjectStage="updateProjectStage"
                                @deleteProjectStage="deleteProjectStage"
                              )
                            .col-4
                              .border.p-2
                                strong Descripción
                                table.table.table-border.table-sm
                                  tbody
                                    tr
                                      td
                                        div(v-if="project.project_description" v-html="project.project_description.description") 
</template>

<script>
  import ProjectStageComponent from '../../components/projects/ProjectStage.vue'
  export default {
    name: 'ProjectShow',
    components: {
      ProjectStageComponent
    },
    beforeMount() {
      this.id = parseInt(this.$route.params.id)
      this.moduleUrl = 'projects'
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })
      this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: this.id})
        .then((res) => {
          this.project = res
        })
    },
    data () {
      return {
        id: 0,
        currentModule: {},
        currentTab: 'info',
        project: {},
      }
    },
    methods: {
      setTab(tab) {
        this.currentTab = tab
      },
      deleteAccount() {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este element?',
          icon: 'warning',
          showCancelButton: true
        }).then(function (result) {
          console.log(result)
        });
      },
      createProjectStage(res) {
        this.project.project_stages.push(res)
      },
      updateProjectStage(res) {
        let index = this.project.project_stages.findIndex(x => x.id == res.id)
        if(index > -1) {
          this.$set(this.project.project_stages, index, res)
        }
      },
      deleteProjectStage(res) {
        let index = this.project.project_stages.findIndex(x => x.id == res.id)
        if(index > -1) {
          this.project.project_stages.splice(index, 1)
        }
      }
    }
  }
</script>