<template lang="pug">
  .account-addresses
    .border.p-2
      .float-end
        button.btn.btn-primary.btn-sm(type='button' data-bs-toggle='modal' data-bs-target='#accountAddressModal' @click="setDefaultAccountAddress")
          font-awesome-icon.me-2(icon="plus-circle")
          span Añadir
      strong Direcciones
      table.table.table-border.table-sm
        tbody
          tr(v-for="accountAddress in accountAddresses" :key="'accountAddress' + accountAddress.id")
            td
              div
                .float-end
                  button.btn.btn-link.text-dark(type='button' data-bs-toggle='modal' data-bs-target='#accountAddressModal' @click="editAccountAddress(accountAddress)")
                    font-awesome-icon(icon="edit")
                  button.btn.btn-link.text-dark(type='button' @click="deleteAccountAddress(accountAddress)")
                    font-awesome-icon(:icon="['fas', 'trash']")
                strong {{accountAddress.address}}
                br
                strong {{accountAddress.postal_code}} {{accountAddress.location}}
                br
                small {{accountAddress.name}}
              
                            
      #accountAddressModal.modal(v-if="accountAddress" tabindex='-1' aria-hidden="true") 
        .modal-dialog.modal-lg
          .modal-content
            .modal-header
              h5.modal-title Añadir una dirección
              button#accountAddressModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
            .modal-body
              draw-simp-form(
                :module="accountAddressModule" 
                :value="accountAddress"
                @createRegister="createAccountAddress"
                @updateRegister="updateAccountAddress"
              )
</template>

<script>
  import jQuery from 'jquery'
  import DrawSimpForm from '../forms/DrawSimp.vue'
  export default {
    name: 'AccountAddresses',
    components: {
      DrawSimpForm
    },
    props: {
      accountId: {type: Number, default: 0},
      accountAddresses: {type: Array, default: function() {return [] }},
    },
    beforeMount() {
      
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'account_addresses'})
        .then((res) => {
          this.accountAddressModule = res
        })
    },
    data () {
      return {
        accountAddressModule: {},
        accountAddress: {}
      }
    },
    methods: {
      setDefaultAccountAddress() {
        console.log('test')
        this.accountAddress = {}
        this.accountAddress.account_id = this.accountId
      },
      editAccountAddress(accountAddress) {
        this.accountAddress = accountAddress
      },
      createAccountAddress(res) {
        this.$emit("createAccountAddress", res) 
        this.accountAddress = {}
        jQuery('#accountAddressModalClose').trigger('click')
      },
      updateAccountAddress(res) {
        this.$emit("updateAccountAddress", res) 
        this.accountAddress = {}
        jQuery('#accountAddressModalClose').trigger('click')
      },
      deleteAccountAddress(accountAddress) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: this.accountAddressModule.url,
              id: accountAddress.id
            }).then((res) => {
              this.$emit("deleteAccountAddress", res) 
            })
          }
        });
      }
    }
  }
</script>