<template lang="pug">
  #header
    #companyModal.modal.fade(tabindex='-1' aria-labelledby='companyModalLabel' aria-hidden='true')
      .modal-dialog
        .modal-content
          .modal-header
            h5#companyModalLabel.modal-title Seleccione una empresa
            button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            v-select(:options="companies" :reduce="label => label.id"  label="label" v-model="newCompanyId")
          .modal-footer
            button.btn.btn-primary(type='button' @click="changeCompany()") Save changes

    #offcanvasExample.offcanvas.offcanvas-start.main-menu(tabindex='-1' aria-labelledby='offcanvasExampleLabel')
      .offcanvas-header
        h5#offcanvasExampleLabel.offcanvas-title Men&uacute;
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        .text-center.cont-logo
          // img.rounded(src='http://flexandlux.es/wp-content/uploads/2020/09/FelxLux_negro-2.png')
        ul.list-unstyled.ps-0
          li.mb-1(v-for="mainMenuItem in mainMenu")
            button.btn.btn-toggle.align-items-center.rounded.collapsed(data-bs-toggle='collapse' :data-bs-target="'#item' + mainMenuItem.id + '-collapse'" aria-expanded='true')
              font-awesome-icon(:icon='mainMenuItem.icon')
              span.ps-2 {{mainMenuItem.name}}
            .collapse(:id="'item' + mainMenuItem.id + '-collapse'")
              ul.btn-toggle-nav.list-unstyled.fw-normal.pb-1.small
                li(v-for="mainMenuSubitem in mainMenuItem.main_menu_items")
                  router-link.link-dark(:to="mainMenuSubitem.url") {{mainMenuSubitem.name}}
    nav.header-nav
      .header-nav-content
        .row.justify-content-between
          .col-6
            .d-flex.flex-row.header-nav-menu
              .p-2.header-nav-menu-item
                a(data-bs-toggle='offcanvas' href='#offcanvasExample' role='button' aria-controls='offcanvasExample')
                  font-awesome-icon(icon='bars')
              .p-2.header-nav-menu-item
                a(v-if="user && user.company_id" href='#' data-bs-toggle="modal" data-bs-target="#companyModal")
                  strong {{user.company.name}}
          .col-6
            .d-flex.flex-row-reverse.header-nav-menu-right
              .p-2.header-nav-menu-item
                a(href='#')
                  span {{user.name}}&nbsp;
                  font-awesome-icon.text-primary(:icon="['fas', 'angle-down']")
              .p-2.header-nav-menu-item
                a.position-relative(href='#')
                  font-awesome-icon(:icon="['fas', 'envelope']")
                  span.position-absolute.top-0.start-100.translate-middle.badge.rounded-pill.bg-danger 1
                    span.visually-hidden unread messages
              .p-2.header-nav-menu-item
                a.position-relative(href='#')
                  font-awesome-icon(:icon="['fas', 'bell']")
                  span.position-absolute.top-0.start-100.translate-middle.badge.rounded-pill.bg-danger 1
                    span.visually-hidden unread messages
              .p-2.header-nav-menu-item
                a(href='#')
                  font-awesome-icon(:icon="['fas', 'id-card']")
              .p-2.header-nav-menu-item
                a(href='#')
                  font-awesome-icon(:icon="['fas', 'users']")
              .me-2
                button.btn.btn-round.btn-light(data-bs-toggle='offcanvas' data-bs-target='#offcanvasRight' aria-controls='offcanvasRight')
                  font-awesome-icon(:icon="['fas', 'phone']")
              task-header
              .me-2
                button.btn.btn-round.btn-light(data-bs-toggle='offcanvas' data-bs-target='#offcanvasRight' aria-controls='offcanvasRight')
                  font-awesome-icon(:icon="['fas', 'coffee']")
              .me-2(style="display:none")
                button.btn.btn-round.btn-success
                  font-awesome-icon(:icon="['fas', 'phone']")
                  span.ps-2 00:00:00
              .me-2(style="display:none")
                button.btn.btn-round.btn-danger
                  font-awesome-icon(:icon="['fas', 'coffee']")
                  span.ps-2 00:00:00
</template>
<script>
import TaskHeader from "./tasks/TaskHeader.vue"
export default {
  name: 'Header',
  components: {
      TaskHeader
    },
  data() {
    return {
      mainMenu: [],
      user: {},
      newCompanyId: 0
    }
  },
  computed: {
    companies() {
      let companies = [];
      if(this.user && this.user.companies) {
        this.user.companies.forEach((company) => {
          companies.push({id: company.id, label: company.name})
        })
      }

      return companies
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.user) {
      this.user = user.user
    }

    if(user && user.main_menu) {
      this.mainMenu = user.main_menu
    }
  },
  methods: {
    changeCompany() {
      this.$store.dispatch("changeCompany", { companyId: this.newCompanyId})
        .then(() => {
          location.reload()
        })
    }
  }
}
</script>
<style scoped>
  .text-primary {
  color: #00aaff;
  }
  .text-danger {
  color: #d93651;
  }
  .text-warning {
  color: #ff9f1a;
  }
  .text-success {
  color: #47cc8a;
  }
  .text-info {
  color: #8f7ee6;
  }

  .bg-primary {
  background: #00aaff !important;
  }
  .bg-danger {
  background: #d93651 !important;
  }
  .bg-warning {
  background: #ff9f1a !important;
  }
  .bg-success {
  background: #47cc8a !important;
  }
  .bg-info {
  background: #8f7ee6 !important;
  }

  .btn-icon {
  margin-right: 5px;
  }

  .btn.btn-primary {
  background: #00aaff;
  border-color: #00aaff;
  }
  .btn.btn-danger {
  background: #d93651;
  border-color: #d93651;
  }
  .btn.btn-warning {
  background: #ff9f1a;
  border-color: #ff9f1a;
  }
  .btn.btn-success {
  background: #47cc8a;
  border-color: #47cc8a;
  }
  .btn.btn-info {
  background: #8f7ee6;
  border-color: #8f7ee6;
  color: white;
  }
  .btn.btn-light {
  background: #dedede;
  border-color: #dedede;
  }

  .btn.btn-outline-primary {
  border-color: #00aaff;
  color: #00aaff;
  }
  .btn.btn-outline-primary:hover {
  background-color: #00aaff;
  color: #ffffff;
  }
  .btn.btn-outline-danger {
  color: #d93651;
  border-color: #d93651;
  }
  .btn.btn-outline-danger:hover {
  background: #d93651;
  color: #ffffff;
  }
  .btn.btn-outline-warning {
  color: #ff9f1a;
  border-color: #ff9f1a;
  }
  .btn.btn-outline-warning:hover {
  background-color: #ff9f1a;
  color: #ffffff;
  }
  .btn.btn-outline-success {
  color: #47cc8a;
  border-color: #47cc8a;
  }
  .btn.btn-outline-success:hover {
  background: #47cc8a;
  color: #ffffff;
  }
  .btn.btn-outline-info {
  color: #8f7ee6;
  border-color: #8f7ee6;
  }
  .btn.btn-outline-info:hover {
  background: #8f7ee6;
  color: white;
  }

  .alert.alert-primary {
  background: #00aaff;
  border-color: #00aaff;
  color: white;
  }
  .alert.alert-danger {
  background: #d93651;
  border-color: #d93651;
  color: white;
  }
  .alert.alert-warning {
  background: #ff9f1a;
  border-color: #ff9f1a;
  color: white;
  }
  .alert.alert-success {
  background: #47cc8a;
  border-color: #47cc8a;
  color: white;
  }
  .alert.alert-info {
  background: #8f7ee6;
  border-color: #8f7ee6;
  color: white;
  }
  .alert.alert-dark {
  background: #000000;
  border-color: #000000;
  color: white;
  }
  .alert.alert-secondary {
  background: #6c757d;
  border-color: #6c757d;
  color: white;
  }

  /* GENERAL */
  .btn-round {
  border-radius: 20px;
  }

  br-0 {
  border-radius: 0px !important;
  }

  .btn-link, .link {
  color: #3d474d;
  }
  
  .background {
  /* background: rgba(0,0,0,0.8);
  min-height: calc(100vh - 60px); */
  }
  #app {
  /* background: url('https://randompicturegenerator.com/img/national-park-generator/gca0d3064a837b1f03550658a44aa1a0054c1da3ad0f423377380f1920f2a2362bc5e5a9fb8b5b7bf2fe0e970cf532570_640.jpg');
  background-size: cover; */
  background: #f0f0f0;
  min-height: calc(100vh - 60px);
  padding-top: 60px;
  max-width: 100%;
  }
  /* HEADER */
  .header-nav {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 60px;
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 5px 0px;
  color: #3d474d;
  background: white;
  font-size: 15px;
  z-index: 100;
  }
  .header-nav-content {
  padding: 15px 0px;
  }
  .header-nav-menu a {
  color: #3d474d;
  text-decoration: none;
  }
  .header-nav-menu .header-nav-menu-item {
  padding: 5px 15px !important;
  border-left: 1px solid rgb(220, 226, 230);
  }
  .header-nav-menu .header-nav-menu-item:first-child {
  border-left: 0px;
  }
  .header-nav-menu-right a {
  color: #3d474d;
  text-decoration: none;
  }
  .header-nav-menu-right .header-nav-menu-item {
  padding: 5px 15px !important;
  border-right: 1px solid rgb(220, 226, 230);
  }
  .header-nav-menu-right .header-nav-menu-item:first-child {
  border-right: 0px;
  }
  /* HEADER ACTIONS */
  .header-actions {
  position: fixed;
  top: 60px;
  width: 100%;
  background: #fafafa;
  height: 50px;
  z-index: 50;
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 5px 0px;
  }

  /* MAIN CONTENT */
  .main-content {
  padding-top: 50px;
  }

  /* MENU LEFT */
  .main-menu {
  color: #ffffff;
  }
  .main-menu .cont-logo {
  margin-left: -16px;
  margin-right: -16px;
  background: #ffffff;
  margin-bottom: 15px;  
  }
  .main-menu .cont-logo img {
  max-height: 100px;
  }
  .main-menu .offcanvas-header, .main-menu .offcanvas-body {
  background: #272d31;
  }
  .main-menu a, .main-menu button {
  color: #ffffff;
  }
  .main-menu a:focus, .main-menu button:focus, .main-menu .btn-check:focus+.btn, .main-menu .btn:focus {
  box-shadow: none !important;
  }
  .main-menu .offcanvas-header .btn-close {
  color: #ffffff !important;
  }
  .btn-toggle-nav a {
  display: inline-flex;
  padding: .2875rem .5rem;
  margin-left: 1.25rem;
  text-decoration: none;
  border-left: 1px solid #ffffff;
  }

  /* RECAP */
  .recap-totals-item {
  border-right: 1px solid #d0d0d0;
  }
  .recap-totals .recap-totals-item:first-child{
  border-right: 0px;
  }
  .recap-totals-item small {
  display: block;
  color: #707070;
  }
  .recap-totals-item strong {
  font-size: 1.1em
  }

  /* TABS */
  .nav > .nav-link {
  color: #323232;
  border-bottom: 3px solid transparent;
  padding-bottom: 5px;
  }
  .nav > .nav-link:hover {
  color: #323232;
  border-color: #323232;
  padding-bottom: 5px;
  }

  .nav > .nav-link.active {
  color: #00aaff;
  border-bottom: 3px solid #00aaff;
  padding-bottom: 5px;
  }
  .nav > .nav-link.active:hover {
  color: #00aaff;
  border-color: #00aaff;
  padding-bottom: 5px;
  }
</style>
