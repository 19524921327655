import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import 'bootstrap';
import CKEditor from 'ckeditor4-vue';
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from "vue-moment";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faBars,
  faAngleDown,
  faAngleUp,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faBell,
  faEnvelope,
  faStopwatch,
  faEdit,
  faTrash,
  faEllipsisV,
  faPhone,
  faCoffee,
  faEye,
  faPlusCircle,
  faUsers,
  faIdCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTimes,
  faBriefcase,
  faTruckLoading,
  faTasks,
  faUserCog,
  faUserShield,
  faFileInvoice,
  faInbox,
  faArrowLeft,
  faSave,
  faEuroSign,
  faBan,
  faPlay,
  faStop,
  faCalendar,
  faPaperclip,
  faTrafficLight,
  faFlag,
  faLayerGroup,
  faColumns,
  faCogs,
  faPaste,
  faUpload
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faBars, faAngleUp, faAngleDown, faBell, faEnvelope, faStopwatch, faEdit, faTrash, faEllipsisV, faPhone, faCoffee, faEye, faPlusCircle, faUsers, faIdCard, faAngleDoubleLeft, faAngleDoubleRight, faTimes, faBriefcase, faTruckLoading, faTasks, faUserCog, faUserShield, faFileInvoice, faInbox, faArrowLeft, faSave, faEuroSign, faBan, faPlay, faStop, faCalendar, faPaperclip, faTrafficLight, faFlag, faLayerGroup, faColumns, faCogs, faPaste, faUpload);

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

Vue.use( CKEditor );
Vue.use(VueSweetalert2);
Vue.use(moment)


Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);

Vue.filter('timeInHours', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);
      let dHours = (hours > 9 ? hours : '0' + hours);
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      let dSecs = (seconds > 9 ? seconds : '0' + seconds);
      return dHours + ":" + dMins + ":" + dSecs;
  } else {
      return '00:00:00';
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
      return Vue.moment(new Date(value)).format("DD-MM-YYYY")
  } else {
      return ''
  }
});
Vue.filter('formatTime', function (value) {
  if (value) {
      let parts = value.split(":")

      return parts[0] + ':' + parts[1]
  } else {
      return ''
  }
});

Vue.filter('diffToNow', function (value) {
  let start = new Date(value);
  let end = new Date();

  return (end.getTime() / 1000) - (start.getTime() / 1000)
});

Vue.filter('timeInHours', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);
      let dHours = (hours > 9 ? hours : '0' + hours);
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      let dSecs = (seconds > 9 ? seconds : '0' + seconds);
      return dHours + ":" + dMins + ":" + dSecs;
  } else {
      return '00:00:00';
  }
});

Vue.filter('shortTileCapitalize', function(str) {
  let splitStr = str.toLowerCase().split(' ');
  let stringShot = '';
  
  for (var i = 0; i < splitStr.length; i++) {
    stringShot = stringShot + splitStr[i].charAt(0).toUpperCase();
  }
   
  return stringShot; 
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
