<template lang="pug">
  .draw-table(v-if="currentModule.id")
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
          .p-2
            .input-group
              button.btn.btn-outline-secondary(type='button' @click="prevPage()" v-if="page > 1")
                font-awesome-icon(icon='angle-double-left')
              select.form-select.border-secondary(aria-label='Default select example' v-model="page" @change="search()")
                option(v-for='i in totalPages' :key="'page' + i" :value='i') {{i}}
              button.btn.btn-outline-secondary(type='button' @click="nextPage()" v-if="page < totalPages")
                font-awesome-icon(icon='angle-double-right')
          .p-2
            form(@submit.prevent="search()")
              input.form-control.me-2(type='search' placeholder='Buscar' aria-label='Search' v-model="filter")
          .p-2
            .btn-group
              router-link.btn.btn-outline-primary(:to="'/' + currentModule.url +'/add'")
                font-awesome-icon.me-2(:icon="['fas', 'plus-circle']")
                span A&ntilde;adir
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card
                .card-body
                  .row
                    .col-6
                      h5.card-title {{currentModule.name}}
                    .col-6
                      p.text-end 
                        span Mostrando 
                        strong {{limitStart}} - {{limitEnd}}
                        span &nbsp;de&nbsp; 
                        strong {{totalElements}}
                  .table-responsive
                    table.table.table-striped.table-hover
                      thead
                        tr
                          th(v-for="column in currentModule.module_table_cols" :key="'th' + column.id" @click="changeSortDirection(column)" ) 
                            template(v-if="sortColumn == column.form")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span {{column.label}}
                          th Acciones
                      tbody
                        tr(v-for="register in registers" :key="'tr' + register.id")
                          td(v-for="column in currentModule.module_table_cols" :key="'td' + column.id")
                            template(v-if="column.object_name")
                              template(v-if="register[column.object_name] && register[column.object_name][column.object_key]")
                                span.badge(v-if="column.is_tag" :style="'background:' + register[column.object_name]['background'] + ';color:' + register[column.object_name]['color'] + ';'") {{register[column.object_name][column.object_key]}}
                                span(v-else) {{register[column.object_name][column.object_key]}}
                              template(v-else)
                                span -
                            template(v-else)
                              span.badge.bg-success(v-if="column.is_boolean && register[column.object_key]") Si
                              span.badge.bg-secondary(v-else-if="column.is_boolean && !register[column.object_key]") No
                              span.badge.bg-danger(v-else-if="column.is_error && register[column.object_key]") Error
                              span.badge.bg-success(v-else-if="column.is_error && !register[column.object_key]") Correcto
                              span(v-else) {{register[column.object_key]}}
                          td
                            template(v-for="moduleTableAction in currentModule.module_table_actions")
                              button.btn.btn-link.text-dark.btn-sm(@click.stop="clickAction(moduleTableAction, register)")
                                font-awesome-icon(:icon="moduleTableAction.icon")

</template>
<script>
export default {
  name: 'DrawTable',
  components: {
    
  },
  props: {
    currentModule: {type: Object, default: function() {return {} }},
  },
  data () {
    return {
      page: 1,
      registers: [],
      totalPages: 1,
      totalElements: 0,
      sortColumn: 'name',
      sortDirection: 1,
      limit: 25,
      limitStart: 0,
      limitEnd: 0,
      filter: ''
    }
  },
  mounted() {    
  },
  watch: {
    currentModule(newVal) {
      if(newVal.url) {
        this.sortColumn = newVal.module_table_cols[0].form
        setTimeout(()=> {this.search()}, 500)
      }
    },
    filter() {
      this.page = 1
      this.search()
    },
  },
  methods: {
    search() {
      this.$store.dispatch("searchRegisters", {
        moduleUrl: this.currentModule.url,
        page: this.page,
        data: {
          filter: this.filter,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          limit: this.limit,
        }
      }).then((res) => {
        this.registers = res.data
        this.totalPages = res.total_pages
        this.limit = res.limit
        this.limitStart = res.limit_start + 1
        this.limitEnd = res.limit_start + res.limit
        this.totalElements = res.total
        if(this.limitEnd > this.totalElements) {
          this.limitEnd = this.totalElements
        }
      })
    },
    prevPage() {
      this.page--
      this.search();
    },
    nextPage() {
      this.page++
      this.search();
    },
    changeSortDirection(column) {
      if(column.form == this.sortColumn) {
        this.sortDirection = this.sortDirection * -1
      } else {
        this.sortColumn = column.form
        this.sortDirection = 1
      }

      this.search()
    },
    clickAction(moduleTableAction, register) {
      if(moduleTableAction.url) {
        this.$router.push(moduleTableAction.url.replace('##ID##', register.id))
      } else {
        switch(moduleTableAction.action) {
          case 'deleteRegister':
            this.$swal.fire({
              title: 'Está seguro que desea eliminar este element?',
              icon: 'warning',
              showCancelButton: true
            }).then((result) => {
              if(result) {
                this.$store.dispatch("deleteRegister", {
                  moduleUrl: this.currentModule.url,
                  id: register.id
                }).then(() => {
                  this.search()
                })
              }
            });
            break;
        }
      }
    }
  }
}
</script>
<style scoped>
</style>