<template lang="pug">
  .task-show
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
          .p-2
            .btn-group.me-2
              a.btn.btn-outline-secondary(type='button' href="/tasks")
                font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
                span Volver
            .btn-group.me-2
              a.btn.btn-outline-primary(type='button' :href="'/tasks/' + id + '/edit'")
                font-awesome-icon.me-2(:icon="['fas', 'edit']")
                span Editar
            .btn-group.me-2
              button.btn.btn-outline-danger(type='button' @click="deleteTask()")
                font-awesome-icon.me-2(:icon="['fas', 'trash']")
                span Eliminar
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card
                .card-body
                  task-view(:taskId="id")


                  
</template>

<script>
  import TaskView from '../../components/tasks/TaskView.vue'
  export default {
    name: 'TaskShow',
    components: {
      TaskView
    },
    beforeMount() {
      this.id = parseInt(this.$route.params.id)
    },
    data () {
      return {
        id: 0,
        currentModule: {},
      }
    },
    methods: {
      setTab(tab) {
        this.currentTab = tab
      },
      deleteTask() {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este element?',
          icon: 'warning',
          showCancelButton: true
        }).then(function (result) {
          console.log(result)
        });
      },
    }
  }
</script>