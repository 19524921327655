<template lang="pug">
  #app
    template(v-if="user && user.id")
      Header
    router-view
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  },
  data () {
    return {
      user: {}
    }
  },
  mounted() {
    if(localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      this.user = user.user
    }
  },
}
</script>

<style lang="scss">
  @import "vue-select/src/scss/vue-select.scss";
  @import './scss/main.scss';
</style>
