<template lang="pug">
  .module-add
    draw-form(
      :module="currentModule"
    )
</template>

<script>
  import DrawForm from '../../components/forms/Draw.vue'

  export default {
    name: 'AccountAdd',
    components: {
      DrawForm
    },
    beforeMount() {
      this.moduleUrl = this.$route.params.moduleUrl
      if(this.$route.params.parentModuleUrl) {
        this.$store.dispatch("getModuleByUrl", {moduleUrl: this.parentModuleUrl})
          .then((parentRes) => {
            this.currentParentModule = parentRes
            this.foreignKey = parentRes.class_name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
            this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
              .then((res) => {
                this.currentModule = res
                this.$set(this.object, this.foreignKey, this.$route.params.parentId)
              })
          })
      } else {
        this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
          .then((res) => {
            this.currentModule = res
          })
      }
    },
    data () {
      return {
        moduleUrl: '',
        currentParentModule: {},
        foreignKey: '',
        currentModule: {},
        object: {},
      }
    },
    methods: {
    }
  }
</script>