<template lang="pug">
  .module-lists
    draw-table(:currentModule="currentModule")
</template>

<script>
  import DrawTable from '../../components/tables/Draw.vue'
  export default {
    name: 'ModuleList',
    components: {
      DrawTable
    },
    data() {
      return {
        currentModule: {}
      }
    },
    beforeMount() {
      this.moduleUrl = this.$route.params.moduleUrl
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })
    },
    watch: {
      $route(to, from) {
        if(to.params.moduleUrl != from.params.moduleUrl) {
          this.moduleUrl = to.params.moduleUrl
          this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
            .then((res) => {
              this.currentModule = res
            })
        }
      }
    },
    methods: {
      
    }
  }
</script>