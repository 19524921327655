import Vue from 'vue'
import tasksService from '../services/api/tasks'

const taskModule = {
    state: {
        task: {},
        startedTasks: [],
        tasks: []
    },
    mutations: {
        addTasks (state, task) {
            let index = state.tasks.findIndex(x => x.id === task.id)

            if(index == -1) {
                state.tasks.push(task)
            }
        },
        setTasks (state, tasks) {
            state.tasks = tasks
        },
        updateTasks (state, task) {
            let index = state.tasks.findIndex(x => x.id === task.id)

            if(index > -1) {
                Vue.set(state.tasks, index, task)
            }
        },
        deleteTasks (state, task) {
            let index = state.tasks.findIndex(x => x.id === task.id)

            if(index > -1) {
                state.tasks.splice(index,1)
            }
        },
        addStartedTasks (state, task) {
            let index = state.startedTasks.findIndex(x => x.id === task.id)

            if(index == -1) {
                state.startedTasks.push(task)
            }
        },
        setStartedTasks (state, tasks) {
            state.startedTasks = tasks
        },
        updateStartedTasks (state, task) {
            let index = state.startedTasks.findIndex(x => x.id === task.id)

            if(index > -1) {
                Vue.set(state.startedTasks, index, task)
            }
        },
        deleteStartedTasks (state, task) {
            let index = state.startedTasks.findIndex(x => x.id === task.id)

            if(index > -1) {
                state.startedTasks.splice(index,1)
            }
        },
        setTask  (state, task) {
            state.task = task
        }
    },
    actions: {
        startTask (context, payload) {
            return new Promise((resolve, reject) => {
                tasksService.start(payload.taskId)
                    .then((task) => {
                        context.commit('addStartedTasks', task)
                        context.commit('updateTasks', task)
                        resolve(task)
                    })
                    .catch(reject)
            })
        },
        endTask (context, payload) {
            return new Promise((resolve, reject) => {
                tasksService.end(payload.taskId)
                    .then((task) => {
                        context.commit('deleteStartedTasks', task)
                        context.commit('updateTasks', task)
                        resolve(task)
                    })
                    .catch(reject)
            })
        },
        getMyTasks(context) {
            return new Promise((resolve, reject) => {
                tasksService.myTasks()
                    .then((tasks) => {
                        context.commit('setTasks', tasks)
                        resolve(tasks)
                    })
                    .catch(reject)
            })
        },
        getMyCurrentTasks(context) {
            return new Promise((resolve, reject) => {
                tasksService.myCurrentTasks()
                    .then((tasks) => {
                        context.commit('setStartedTasks', tasks)
                        resolve(tasks)
                    })
                    .catch(reject)
            })
        }
    }
}

export default taskModule