<template lang="pug">
  #commentView
    comment-form(:parentId="parentId" :parentKey="parentKey" :module="module" @newComment="newComment")
    .bg-light.p-2.mt-2.rounded(v-for="comment in comments" :key="'comment' + comment.id")
      div(v-html="comment.comment")
      font-awesome-icon.me-2(icon="user")
      strong {{comment.user.name}}
      font-awesome-icon.ms-2.me-2(icon="calendar")
      span {{comment.created_at | moment('DD-MM-YYYY hh:mm:ss')}}
</template>
<script>
import CommentForm from "./Form.vue"
export default {
  name: 'commentView',
  props: {
    parentId: { type: Number },
    parentKey: { type: String, default: '' },
    module: { type: Object },
    comments: []
  },
  components: {
    CommentForm
  },
  data() {
    return {
      comment: '',
      editorConfig: [],
    }
  },
  computed: {
    
  },
  mounted() {
  },
  methods: {
    newComment(comment) {
      this.$emit("newComment", comment)
    }
  }
}
</script>
<style scoped>

</style>
