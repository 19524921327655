import appService from "./appService";

const tasksService = {
    myCurrentTasks() {
        return appService.get('/tasks/my_current_tasks')
            .then((res) => res.data)
    },
    myTasks() {
        return appService.get('/tasks/my_tasks')
            .then((res) => res.data)
    },
    start(taskId) {
        return appService.get('/tasks/' + taskId + '/start')
            .then((res) => res.data)
    },
    end(taskId) {
        return appService.get('/tasks/' + taskId + '/end')
            .then((res) => res.data)
    }
}

export default tasksService;