<template lang="pug">
  .account-show
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
            .btn-group.me-2.ms-2
              .dropdown
                button.btn.btn-outline-primary.dropdown-toggle(type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false")
                  font-awesome-icon.me-2(:icon="['fas', 'plus-circle']")
                  span Generar
                ul.dropdown-menu(aria-labelledby="dropdownMenuButton1")
                  li
                    a.dropdown-item(href="#") Pedido
                    a.dropdown-item(href="#") Albarán
                    a.dropdown-item(href="#") Factura
                    a.dropdown-item(href="#") Pago
          .p-2
            .btn-group.me-2
              a.btn.btn-outline-secondary(type='button' href="/accounts")
                font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
                span Volver
            .btn-group.me-2
              a.btn.btn-outline-primary(type='button' :href="'/accounts/' + this.id + '/edit'")
                font-awesome-icon.me-2(:icon="['fas', 'edit']")
                span Editar
            .btn-group.me-2
              button.btn.btn-outline-danger(type='button' @click="deleteAccount()")
                font-awesome-icon.me-2(:icon="['fas', 'trash']")
                span Eliminar
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card
                .card-body
                  .row
                    .col-4
                      h5.card-title {{account.name}}
                      p {{'#' + account.code}}
                    .col-8
                      .d-flex.flex-row-reverse.text-center.recap-totals
                        .p-2.recap-totals-item
                          small Direcciones
                          strong 2
                        .p-2.recap-totals-item
                          small Email
                          strong 5
                        .p-2.recap-totals-item
                          small Teléfonos
                          strong 5
                        .p-2.recap-totals-item
                          small Facturas
                          strong 5
                        .p-2.recap-totals-item
                          small Albaranes
                          strong 5
                        .p-2.recap-totals-item
                          small Pedidos
                          strong 5
                  .row.mt-2
                    .col-12
                      nav.nav.mb-2
                        a.nav-link(:class="currentTab == 'info' ? 'active' : ''" aria-current='page' @click="setTab('info')") Información
                        a.nav-link(:class="currentTab == 'contacts' ? 'active' : ''" aria-current='page' @click="setTab('contacts')") Formas de contacto
                        a.nav-link(:class="currentTab == 'orders' ? 'active' : ''" aria-current='page' @click="setTab('orders')") Pedidos
                        a.nav-link(:class="currentTab == 'deliveryNotes' ? 'active' : ''" aria-current='page' @click="setTab('deliveryNotes')") Albaranes
                        a.nav-link(:class="currentTab == 'invoices' ? 'active' : ''" aria-current='page' @click="setTab('invoices')") Facturas
                        a.nav-link(:class="currentTab == 'payments' ? 'active' : ''" aria-current='page' @click="setTab('payments')") Pagos
                    .col-12
                      .p-2
                        template(v-if="currentTab == 'info'")
                          .row
                            .col-4
                              .border.p-2
                                strong Información básica
                                table.table.table-border.table-sm
                                  tbody
                                    tr
                                      td
                                        small Tipo
                                        div
                                          strong(v-if="account.account_type_id") {{account.account_type.name}}
                                          strong(v-else) -
                                    tr
                                      td
                                        small Razón Social
                                        div 
                                          strong {{account.corporate_name}}
                                    tr
                                      td
                                        small CIF
                                        div 
                                          strong {{account.vat_number}}
                                    tr
                                      td
                                        small Contacto
                                        div 
                                          strong {{account.contact}}
                                    tr
                                      td
                                        small URL
                                        div 
                                          a(v-if="account.url" :href="account.url" target="_blank") {{account.url}}
                                    tr
                                      td
                                        small Impuestos aplicados
                                        div
                                          span.badge.bg-secondary.me-2(v-for="taxGroup in account.tax_groups") {{taxGroup.name}}
                                    tr
                                      td
                                        small Descripción
                                        div(v-if="account.account_description" v-html="account.account_description.description")
                        template(v-if="currentTab == 'contacts'")
                          .row
                            .col-4
                              account-phones(
                                :accountId="id" 
                                :accountPhones="account.account_phones" 
                                @createAccountPhone="createAccountPhone" 
                                @updateAccountPhone="updateAccountPhone"
                                @deleteAccountPhone="deleteAccountPhone"
                              )
                            .col-4
                              account-emails(
                                :accountId="id" 
                                :accountEmails="account.account_emails" 
                                @createAccountEmail="createAccountEmail" 
                                @updateAccountEmail="updateAccountEmail"
                                @deleteAccountEmail="deleteAccountEmail"
                              )
                            .col-4
                              account-addresses(
                                :accountId="id" 
                                :accountAddresses="account.account_addresses" 
                                @createAccountAddress="createAccountAddress" 
                                @updateAccountAddress="updateAccountAddress"
                                @deleteAccountAddress="deleteAccountAddress"
                              )
                        template(v-if="currentTab == 'orders'")              
                          .row
                            .col-12
                              .border.p-2
                                table.table.table-striped.table-hover
                                  thead
                                    tr
                                      th #
                                      th Número
                                      th Estado
                                      th Fecha
                                      th Nombre
                                      th Importe
                                  tbody
                                    tr(v-for="i in 10")
                                      td {{i}}
                                      td 
                                        a(href="#") FL000{{i}}
                                      td 
                                        span.badge.bg-success Cerrado
                                      td 25-04-2021
                                      td Nombre del contacto
                                      td 100,23 &euro;
                        template(v-if="currentTab == 'deliveryNotes'")              
                          .row
                            .col-12
                              .border.p-2
                                table.table.table-striped.table-hover
                                  thead
                                    tr
                                      th #
                                      th Número
                                      th Estado
                                      th Fecha
                                      th Nombre
                                      th Importe
                                  tbody
                                    tr(v-for="i in 10")
                                      td {{i}}
                                      td 
                                        a(href="#") FL000{{i}}
                                      td 
                                        span.badge.bg-success Entregado
                                      td 25-04-2021
                                      td Nombre del contacto
                                      td 100,23 &euro;
                        template(v-if="currentTab == 'invoices'")              
                          .row
                            .col-12
                              .border.p-2
                                table.table.table-striped.table-hover
                                  thead
                                    tr
                                      th #
                                      th Número
                                      th Estado
                                      th Fecha
                                      th Nombre
                                      th Importe
                                  tbody
                                    tr(v-for="i in 10")
                                      td {{i}}
                                      td 
                                        a(href="#") FL000{{i}}
                                      td 
                                        span.badge.bg-success Pagada
                                      td 25-04-2021
                                      td Nombre del contacto
                                      td 100,23 &euro;
                        template(v-if="currentTab == 'payments'")              
                          .row
                            .col-12
                              .border.p-2
                                table.table.table-striped.table-hover
                                  thead
                                    tr
                                      th #
                                      th Número
                                      th Estado
                                      th Fecha
                                      th Nombre
                                      th Tipo
                                      th Importe
                                  tbody
                                    tr(v-for="i in 10")
                                      td {{i}}
                                      td 
                                        a(href="#") FL000{{i}}
                                      td 
                                        span.badge.bg-success Pagado
                                      td 25-04-2021
                                      td Nombre del contacto
                                      td TPV
                                      td 100,23 &euro;
</template>

<script>
  import accountPhones from '../../components/accounts/AccountPhones.vue'
  import accountEmails from '../../components/accounts/AccountEmails.vue'
  import accountAddresses from '../../components/accounts/AccountAddresses.vue'
  export default {
    name: 'AccountShow',
    components: {
      accountPhones,
      accountEmails,
      accountAddresses,
    },
    beforeMount() {
      this.id = parseInt(this.$route.params.id)
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })
      this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: this.id})
        .then((res) => {
          this.account = res
        })
    },
    data () {
      return {
        id: 0,
        moduleUrl: 'accounts',
        currentTab: 'info',
        account: {}
      }
    },
    methods: {
      setTab(tab) {
        this.currentTab = tab
      },
      deleteAccount() {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este element?',
          icon: 'warning',
          showCancelButton: true
        }).then(function (result) {
          console.log(result)
        });
      },
      createAccountPhone(res) {
        this.account.account_phones.push(res)
      },
      updateAccountPhone(res) {
        let index = this.account.account_phones.findIndex(x => x.id == res.id)
        if(index > -1) {
          this.$set(this.account.account_phones, index, res)
        }
      },
      deleteAccountPhone(res) {
        let index = this.account.account_phones.findIndex(x => x.id == res.id)
        if(index > -1) {
          this.account.account_phones.splice(index, 1)
        }
      },
      createAccountEmail(res) {
        this.account.account_emails.push(res)
      },
      updateAccountEmail(res) {
        let index = this.account.account_emails.findIndex(x => x.id == res.id)
        if(index > -1) {
          this.$set(this.account.account_emails, index, res)
        }
      },
      deleteAccountEmail(res) {
        let index = this.account.account_emails.findIndex(x => x.id == res.id)
        if(index > -1) {
          this.account.account_emails.splice(index, 1)
        }
      },
      createAccountAddress(res) {
        this.account.account_addresses.push(res)
      },
      updateAccountAddress(res) {
        let index = this.account.account_addresses.findIndex(x => x.id == res.id)
        if(index > -1) {
          this.$set(this.account.account_addresses, index, res)
        }
      },
      deleteAccountAddress(res) {
        let index = this.account.account_addresses.findIndex(x => x.id == res.id)
        if(index > -1) {
          this.account.account_addresses.splice(index, 1)
        }
      }
    }
  }
</script>