<template lang="pug">
  #formComment
    ckeditor.mb-2.mt-2(:config="editorConfig" v-model="comment")
    .btn-group
      button.btn.btn-primary(@click="createComment")
        font-awesome-icon.me-2(icon="save")
        span Guardar
</template>
<script>
export default {
  name: 'FormComment',
  props: {
    parentId: { type: Number },
    parentKey: { type: String, default: '' },
    module: { type: Object }
  },
  data() {
    return {
      comment: '',
      editorConfig: {}
    }
  },
  computed: {
    currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
  },
  mounted() {
    
  },
  methods: {
    createComment() {
      let data = {}
      data[this.parentKey] = this.parentId
      data['comment'] = this.comment
      data['user_id'] = this.currentUser.id

      this.$store.dispatch("createRegister", {
          moduleUrl: this.module.url,
          data: data
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.comment = ''
            this.$emit("newComment", res)
          }
        })

    }
  }
}
</script>
<style scoped>

</style>
